<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" ref="doctorForm" @submit.prevent="saveDoctor">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" :elevation="1" height="30px" dark>
                                    <v-toolbar-title class="subtitle-2">Doctor Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="3">Dct. Id</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        :value="$store.getters['doctor/newCode']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Dct. Name</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="doctor.name"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[() => !!doctor.name || 'Enter name']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Mobile</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="doctor.mobile"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[() => !!doctor.mobile || 'Enter mobile']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">E-mail</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="doctor.email"
                                                        dense
                                                        outlined
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Date of Birth</v-col>
                                                <v-col cols="9">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="doctor.date_of_birth"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                autocomplete="off"
                                                            >
                                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="doctor.date_of_birth"
                                                            @input="menu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters> 
                                                <v-col cols="3" class="mt-1">Gender</v-col>
                                                <v-col cols="9">
                                                    <v-radio-group v-model="doctor.gender" row  no-gutters style="margin:0px;padding:0">
                                                        <v-radio
                                                            label="Male"
                                                            value="Male"
                                                        ></v-radio>
                                                        <v-radio
                                                            label="Female"
                                                            value="Female"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Origin</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="doctor.origin"
                                                        dense
                                                        outlined
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Address</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="doctor.address"
                                                        dense
                                                        outlined
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Biography</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="doctor.biography"
                                                        dense
                                                        outlined
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Specialist</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="doctor.specialist"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[() => !!doctor.specialist || 'Enter specialist']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-2">
                                                <v-col cols="3">Day</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :items="days"
                                                        v-model="selectedDay"
                                                        multiple
                                                        required
                                                        :rules="[() => !!this.selectedDay || '']"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3">Fees</v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        label="New"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        autocomplete="off"
                                                        v-model.number="doctor.new_patient_fees"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4" offset="1">
                                                    <v-text-field
                                                        label="Regular"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        autocomplete="off"
                                                        v-model.number="doctor.regular_patient_fees"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Image</v-col>
                                                <v-col cols="9">
                                                    <v-file-input 
                                                        show-size 
                                                        outlined 
                                                        dense
                                                        hide-details
                                                        @change="onChangeMainImage()"
                                                        prepend-icon=""
                                                        prepend-inner-icon="mdi-camera"
                                                    >
                                                    </v-file-input>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-card tile :elevation="0"> 
                                                        <v-img
                                                            :src="image"
                                                            aspect-ratio="1.9"
                                                            class="grey lighten-2"
                                                            contain
                                                        >
                                                        </v-img>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row dense>
                                                         <v-col cols="6">
                                                            <v-btn height="30px" type="reset" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn height="30px" type="submit" dark block color="light-blue darken-2" :loading="saveLoading">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="doctorHeaders"
                    :loading="$store.getters['doctor/loading']"
                    :items="$store.getters['doctor/doctors']"
                    :search="searchDoctor"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Doctor List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    v-model="searchDoctor"
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Doctor"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="showDoctorProfile(item)" color="primary" v-on="on">mdi-eye</v-icon>
							</template>
							<span>View</span>
						</v-tooltip>
						<v-tooltip bottom v-if="userType != 'user'">
							<template v-slot:activator="{ on }">
								<v-icon small @click="editDoctor(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom v-if="userType != 'user'">
							<template v-slot:activator="{ on }">
								<v-icon small @click="doctorId = item.id;$refs.confirmDialog.dialog = true" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deleteDoctor"></confirm-dialog>
                <doctor-profile :data="doctorProfile" ref="doctorProfile"></doctor-profile>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import confirmDialog from "./../../components/Confirm";
import doctorProfile from "./../../components/DoctorProfile";
export default {
    name: 'DoctorEntry',

    components: {
		"confirm-dialog": confirmDialog,
		"doctor-profile": doctorProfile,
    },

    data: ()=> ({
        menu: false,
        apiBasePath: window.apiBaseURL,
        doctorHeaders: [
            { text: 'Id', value: 'code' },
            { text: 'Name', value: 'name' },
            { text: 'Mobile', value: 'mobile' },
            { text: 'Email', value: 'email' },
            { text: 'Specialist', value: 'specialist' },
            { text: 'Address', value: 'address' },
            { text: 'Action', value: 'action' },
        ],
        searchDoctor: '',
        doctors: [],
        doctor: {
            id: null,
            name: '',
            mobile: '',
            email: '',
            date_of_birth: new Date().toISOString().substr(0, 10),
            gender: '',
            origin: '',
            address: '',
            biography: '',
            specialist: '',
            days: '',
            new_patient_fees: 0.00,
            regular_patient_fees: 0.00,
        },
        image: '',
        imageFile: null,
        days: ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        selectedDay: null,
        testDays: [],
        doctorId: null,
        saveLoading: false,
        doctorProfile: {},
        userType: ''
    }),

    created() {
        this.getDoctors();
        this.getCode();
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type
    },

    methods: {
        onChangeMainImage() {
            if(event.target.files == undefined || event.target.files.length < 1) {
                this.image = '';
                this.imageFile = null;
                return;
            }

            this.imageFile = event.target.files[0];
            this.image = URL.createObjectURL(event.target.files[0]);
        },

        getDoctors() {
            this.$store.dispatch('doctor/getDoctors');
        },

        getCode() {
            this.$store.dispatch('doctor/getCode');
        },

        async saveDoctor() {
            if(! this.validationForm()) {
                return;
            }

            this.saveLoading = true;
            this.doctor.days = this.selectedDay;
            let isSuccess = await this.$store.dispatch('doctor/saveDoctor', { doctor: this.doctor, image: this.imageFile });

            if(isSuccess) {
                this.resetForm();
            }

            this.saveLoading = false;
        },

        async editDoctor(doctor) {
            Object.keys(this.doctor).forEach(key => this.doctor[key] = doctor[key]);
            this.image = `${this.apiBasePath}/${doctor.image}`;
            this.selectedDay = String(doctor.days).split(',');
        },

        deleteDoctor() {
            this.$store.dispatch('doctor/deleteDoctor', { id: this.doctorId });
            this.$refs.confirmDialog.dialog = false;
        },

        showDoctorProfile(doctor) {
            this.doctorProfile = doctor
            this.doctorProfile.days = (String(doctor.days).split(',')).join(', ');
            this.$refs.doctorProfile.dialog = true
        },

        validationForm() {
            let isValid = true;
            this.$refs.doctorForm.validate();
            this.$refs.doctorForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            return isValid;
        },

        resetForm() {
            this.$refs.doctorForm.reset();
            this.$refs.doctorForm.resetValidation();
            this.getCode();
            this.doctor.date_of_birth = new Date().toISOString().slice(0, 10)
            this.doctor.new_patient_fees = this.doctor.regular_patient_fees = 0
        }

    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
</style>