<template>
	<v-dialog v-model="dialog" max-width="290" light>
		<v-card>
			<v-card-title class="headline">Confirmation</v-card-title>

			<v-card-text>Are you sure?</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" dark @click="$emit('confirm')">Yes</v-btn>
				<v-btn color="error" dark @click="dialog = false">No</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false
        }
	},
};
</script>

<style>
</style>