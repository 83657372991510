<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-card>
        <v-container>
         <v-card-title>Doctor Prfile</v-card-title>
        <v-card-text class="py-0">
          <v-row dense>
              <v-col cols="4">
                <v-img
                  v-if="data.image != null"
                  :lazy-src="`${apiBasePath}/${data.image}`"
                  height="150"
                  max-width="180"
                  :src="`${apiBasePath}/${data.image}`"
                  contain=""
                ></v-img>
                <v-img
                    v-else
                    lazy-src="@/assets/no-image.jpg"
                    height="150"
                    max-width="180"
                    src="@/assets/no-image.jpg"
                    contain=""
                    style="border: 1px solid rgb(0 0 0 / 25%)"
                ></v-img>
              </v-col>
              <v-col cols="8">
                  <table>
                      <tr>
                          <th>ID</th>
                          <td>:</td>
                          <td>{{ data.code }}</td>
                      </tr>
                      <tr>
                          <th>Name</th>
                          <td>:</td>
                          <td>{{ data.name }}</td>
                      </tr>
                      <tr>
                          <th>Mobile</th>
                          <td>:</td>
                          <td>{{ data.mobile }}</td>
                      </tr>
                      <tr>
                          <th>E-mail</th>
                          <td>:</td>
                          <td>{{ data.email }}</td>
                      </tr>
                      <tr>
                          <th>Date of Birth</th>
                          <td>:</td>
                          <td>{{ data.date_of_birth }}</td>
                      </tr>
                      <tr>
                          <th>Gender</th>
                          <td>:</td>
                          <td>{{ data.gender }}</td>
                      </tr>
                      <tr>
                          <th>Origin</th>
                          <td>:</td>
                          <td>{{ data.origin }}</td>
                      </tr>
                  </table>
              </v-col>
          </v-row>
          <v-row dense>
              <v-col cols="12">
                <table>
                  <tr>
                    <th>Address</th>
                    <td>:</td>
                    <td>{{ data.address }}</td>
                  </tr>
                  <tr>
                    <th>Biography</th>
                    <td>:</td>
                    <td>{{ data.biography }}</td>
                  </tr>
                  <tr>
                    <th>Specialist</th>
                    <td>:</td>
                    <td>{{ data.specialist }}</td>
                  </tr>
                  <tr>
                    <th>Day</th>
                    <td>:</td>
                    <td>
                      {{ data.days }}
                    </td>
                  </tr>
                  <tr>
                    <th>Fees</th>
                    <td>:</td>
                    <td>
                      <strong>New :</strong> {{ data.new_patient_fees }} <strong class="ml-2">Regular :</strong> {{ data.regular_patient_fees }}
                    </td>
                  </tr>
                </table>
              </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ["data"],
    data: () => ({
      dialog: false,
      apiBasePath: window.apiBaseURL,
    }),
    created() {
    }
  }
</script>

<style lang="scss" scoped>
table {
    th {
        text-align: left;
    }
    th, td {
        padding: 0 5px;
    }
}
</style>